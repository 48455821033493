/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Llevaba tiempo queriendo probar un nuevo stack para el blog, la deriva que ha llevado Wordpress los últimos tiempos no me ha gustado demasiado y quería probar algo más ligero y personalizable de forma sencilla."), "\n", React.createElement(_components.p, null, "Por otra parte los blogs servidos estáticamente se han generalizado lo suficiente como para encontrar herramientas de integración continua y hosting gratuito, esto abarata el coste de tener un blog a únicamente el del nombre de dominio."), "\n", React.createElement(_components.p, null, "Así que he portado mi antiguo blog a Gatsby, un generador de webs estáticas basado en React y he utilizado Cloudflare Pages para generar el blog cada vez que suba un cambio a Github."), "\n", React.createElement(_components.p, null, "Además he comprado un nuevo dominio, cyborspaces.com, al que se va a redireccionar el antiguo blog.\nSi hay alguien ahí fuera aún usando RSS como hacemos la resistencia por favor actualiza a ", React.createElement(_components.a, {
    href: "/rss.xml"
  }, "la nueva url"), "."), "\n", React.createElement(_components.p, null, "Así que no me queda más que daros la bienvenida a mi nuevo blog, ", React.createElement(_components.a, {
    href: "/"
  }, "Cyborg Spaces"), ",\nun lugar donde como siempre compartiré mis experiencias tecnológicas y mis desvaríos mentales."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
